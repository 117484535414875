import {version as CurrentVersion} from '../../package.json'
import {lapiAccess} from "./lapi-access";
export default {
    async install(Vue) {
        Vue.prototype.$alfalab =  {
            ...lapiAccess,
            version: () => {
               return CurrentVersion;
            },
            showError(error, dialog = false) {
                if((error.response && error.response.data.message || null) ){
                    if(!dialog){
                        Vue.prototype.$toast.error(error.response.data.message);
                    }else{
                        let message = error.response.data.message;
                        if(error.response.data.errors){
                            message = Object.keys(error.response.data.errors).map(item => error.response.data.errors[item]).join("<br>");
                        }
                        Vue.prototype.$buefy.dialog.alert({
                            title: error.response.data.message,
                            type: 'is-danger',
                            hasIcon: true,
                            icon: 'times-circle',
                            message
                        })
                    }
                }else{
                    let message = error.message + '<br>';
                    try{
                        if(error.errors){
                            message+=JSON.stringify(error.errors);
                        }
                    }catch (e){}
                    Vue.prototype.$toast.error(message);
                }
                console.error(error.message);
            },
            checkVersion : (lastVersion, toast) =>{
                const currentVersion = CurrentVersion;
                //console.log('hola ' + Math.random() + " " + lastVersion)
                if(currentVersion !== lastVersion){
                    toast.warning(`Hay una nueva versión disponible "${lastVersion}" del programa.<br> <a onclick="window.location.reload(true);">Click Aquí Para Actualizar.</a>`, 30000)
                    setTimeout(Vue.prototype.$alfalab.checkVersion, 30000, currentVersion, lastVersion, toast);
                }
            },
            allowOnlyLapi : (user, item) => {
                let status = true;
                if(user && user.data_branch_office){
                    if(item.onlyLapi){
                        if(user.data_branch_office.group && user.data_branch_office.group.toString().toLowerCase() !== 'lapi'){
                            status = false;
                        }
                    }
                }
                return status;
            },
            isValidPayment(payment){
                const result = {status:true, message:''};
                if(payment.type.match(/^(TARJD|TARJT|SRPAD|SRPAG|INTCR|INTDE)$/) && !payment.last_four){
                    result.status =  false;
                    result.message = 'Se deben capturar los últimos 4 dígitos de la tarjeta';
                }
                return result;
            },
            yearsRange(limit = 3, year_complete = false) {
                const current = new Date().getFullYear();
                const years = [];
                for(let i = 0;i < limit;i++){
                    if(year_complete){
                        years.push({value: ((current-i)) , text: (current-i) });
                    }else{
                        years.push({value: ((current-i) % 10) , text: (current-i) });
                    }
                }
                return years;
            },
            enableByKey(user, item){
                let status = true;
                if(user && item.enableKey && user.data_branch_office){
                    //console.log('key:' + item.enableKey + ", enable: " + user.data_branch_office[item.enableKey])
                    return user.data_branch_office[item.enableKey];
                }
                return status;
            },
            canUpdateOrderPayments(user, order){
                console.log('canUpdateOrderPayments')
                if(this.isAdmin(user)){return true;}
                try{
                    return order && order.elaborate === user.key && this.formatDate(order.date) === this.formatDate(new Date()) && order.sign === '';
                }catch (e){
                    return false;
                }
            },
            canCancelOrders(user){
                //return user.rol === 'ADMIN';
                return this.isAdmin(user);
            },
            formatDate(date){
                let month = '' + (date.getMonth() + 1);
                let day = '' + date.getDate();
                let year = date.getFullYear();

                if (month.length < 2)
                    month = '0' + month;
                if (day.length < 2)
                    day = '0' + day;

                return [year, month, day].join('-');
            },
            enableTomaMuestraAction(user){
                return !user.data_branch_office.has_auto_reception_samples;
            },
            reportError(error) {
                const user = Vue.prototype.$auth.user();
                if(error.config.url.toString().indexOf('backend-error') < 0){
                    Vue.prototype.$http.post('backend-error', {
                        error, user
                    }).then().catch();
                }
            },
            selectAllSamplesToPrint(user){
                //console.log(user.data_branch_office.select_all_samples_on_print);
                try{
                    return user.data_branch_office.select_all_samples_on_print;
                }catch (e){
                    return true;
                }
            },
            enableForeignWorkUI(user){
                return user && user.data_branch_office  && user.data_branch_office.extra_settings.filter(item => {
                    return item.type === 'ui.foreign_work'
                }).length > 0;
            },
            typeSearchAuxForeignWork(user){
                let result = user.extra_settings.find(item => {
                    return item.type === 'ui.foreign_work'
                })
                result = !!result ? result.action : null;
                if(!result){
                    result = user.data_branch_office.extra_settings.find(item => {
                        return item.type === 'ui.foreign_work'
                    }).action;
                }
                return result;
            },
            allowEdit(user, order){
                /*
                if(user.rol === 'ADMIN'){
                    return user.rol === 'ADMIN';
                }
                 */
                if(this.isAdmin(user)){return true;}
                try{
                    return  order.sign.toString() === '';
                }catch (e){
                    return false;
                }
            },
            allowShowHistorico(user, order){
                if(order.id && order.order_new){
                    /*
                    if(user.rol === 'ADMIN'){
                        return true;
                    }
                     */
                    if(this.isAdmin(user)){return true;}
                    if(user.extra_settings.find(item => {
                        return item.type === 'ui.show_history'
                    })){
                       return true;
                    }
                }
                return false;
            },
            allowRegistrySamplesWithOrdersign(user, order){
                return (user && user.data_branch_office  && user.data_branch_office.allow_registry_samples_with_order_sign) ||
                    (order.sign.toString().trim() ==='') ;
            },
            isFastMode(user){
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.faster_mode'
                })
            },
            hasObsAsExtra1(user){
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ex.takeobs_ex1'
                })
            },
            focus(user){
                try{
                    if(this.isFastMode(user)){
                        if(this.enableForeignWorkUI(user)){
                            document.querySelector(`#searchorder_preorder`).focus();
                        }else if(!this.enableForeignWorkUI(user)){
                            document.querySelector(`#searchorder_order`).focus();
                        }
                    }else{
                        document.querySelector(`#searchorder_medical_pass`).focus();
                    }
                }catch (e){}
            },
            isDefaultNoWorkSheet(user){
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.no_worksheet'
                })
            },
            confirmExtraData(user, data){
                let result = '';
                if(this.isFastMode(user)){
                    //console.log(data);
                    try{
                        const studies = data.studies.map(item => {
                            return item.name;
                        }).join(' ');
                        const sucursal = user.data_branch_office.name;
                        result = `<br>Sucursal: ${sucursal}<br> Preorder: ${data.preorder}<br>Paciente: ${data.patient.full_name}<br>Estudios: ${studies}`
                    }catch (e){}
                }
                return result;
            },
            async onValidateEmail(object_app, email){
                object_app.$loader.show();
                let result = {};
                if(!email){
                    result = {
                        status: true
                    };
                    object_app.$loader.hide();
                }else{
                    await object_app.$http.post('/validate-email/', {email})
                        .then(({data}) => {
                            result = data;
                        }).catch(errors => {
                            result = {
                                message: errors.email ?errors.email[0] : 'Formato inválido',
                                status: false,
                                allow_continue: false
                            }
                        }).finally(() => {
                            object_app.$loader.hide();
                        });
                }
                return result;
            },
            hasCompanyLock(component){
                let result = {status:false};
                const user = component.$auth.user();
                if(!user){
                    return result;
                }
                let found = null;
                if(user && user.data_branch_office){
                    found = user.data_branch_office.extra_settings.find(item => {
                        return item.type === 'company.only'
                    });
                    if(!found){
                        found = user.extra_settings.find(item => {
                            return item.type === 'company.only'
                        });
                    }
                }
                if(found){
                    result = {
                        status: true,
                        code: found.action
                    }
                }
                return result;
            },
            canEditCompany(component){
                let companyLock = this.hasCompanyLock(component);
                return !(component.readOnly || companyLock.status);
            },
            async getCompany(component, id){
                let result = null;
                return await new Promise((resolve, reject) => {
                    component.$http.get('/companies/' + id)
                        .then(({data}) => {
                            resolve(data.data);
                        })
                });
            },
            canCancelInvoice(user){
                //return user && user.rol && user.rol === 'ADMIN';
                return this.isAdmin(user);
            },
            canDownloadResultLink(user) {
                /*
                 if(user && user.rol && user.rol === 'ADMIN'){
                     return true;
                 }
                 */
                 if(this.isAdmin(user)){return true;}
                 return user && user.data_branch_office && (!!user.data_branch_office.extra_settings.find(item => {
                     return item.type === 'ui.results_link';
                 }) || !!user.extra_settings.find(item => {
                     return item.type === 'ui.results_link';
                 }));
            },
            showConfigPrinters(component, item){
                const $auth = component.$auth;
                const $alfalab = component.$alfalab;
                const user = $auth.user();
                if(item.code && item.code === 'config-printers'){
                    return true;
                }
                if(item.code && item.code === 'config-printers' && user && user.extra_settings && user.extra_settings.find(itemrow => { return itemrow.type === 'printer.config'})){
                    return true;
                }

                if(item.code && item.code === 'config-printers' && user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'printer.config';
                })){
                    return true;
                }

                return !item.onlySide && ($auth.check(item.auth) && ($alfalab.allowOnlyLapi($auth.user(), item)) && ($alfalab.enableByKey($auth.user(), item))  || ($auth.user().rol && $auth.user().rol === 'ADMIN'))
            },
            isIOSamplesFast(component, type){
                const $auth = component.$auth;
                const user = $auth.user();
                if(user && user.data_branch_office){
                    const config = user.data_branch_office.extra_settings.find(item => {
                        return item.type === 'io-samples.fast';
                    });
                    if(config){
                        const action = JSON.parse(config.action);
                        return !!action['date' + type];
                    }
                }
                return false;
            },
            disableForForeignWork(component, isCancel){
                const $auth = component.$auth;
                const user = $auth.user();
                const isFast = user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.foreign_work'
                });
                return !!(isFast && isCancel);
            },
            enableCaputureSarsResults(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                const hasAccess = user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.sars-enable'
                });
                return !!hasAccess;
            },
            enableSchedule(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                const hasAccess = user && user.data_branch_office && user.active_branch_office == 96;
                return hasAccess;
            },
            enableConfigureContainerLabels(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                /*
                if(user.rol === 'ADMIN'){
                    return true;
                }
                 */
                if(this.isAdmin(user)){return true;}
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.set_num_labels'
                })
            },
            enableMonitorXray($auth) {
                return true;
               // const $auth = component.$auth;
                const user = $auth.user();
                /*
                if(user.rol === 'ADMIN'){
                    return true;
                }
                 */
                if(this.isAdmin(user)){return true;}
                if(user.enabled_xray_monitor_users){
                    return true;
                }
            },
            enableMonitorLaboratory($auth) {
               // const $auth = component.$auth;
                const user = $auth.user();
                /*
                if(user.rol === 'ADMIN'){
                    return true;
                }
                 */
                if(this.isAdmin(user)){return true;}
                if([].indexOf(user.key) > -1){
                    return true;
                }
            },
            canGenerateDocument(user, item) {
                /*
                if(user.rol === 'ADMIN' && item.action){
                    return true;
                }
                 */
                if(this.isAdmin(user) && item.action){
                    return true;
                }
            },
            canEditPatient(component){
                const user = component.$auth.user();
              //console.log('canEditPatient', component.$auth.user(), component.data,component.data.can_edit_basic_info );
                /*
                if(user.rol === 'ADMIN'){
                    return true;
                }
                 */
                if(this.isAdmin(user)){return true;}
                return !!component.data.can_edit_basic_info || typeof component.data.can_edit_basic_info === 'undefined'
            },
            showEditMedicalButton(component) {
                const user = component.$auth.user();
                if(!(component.data.order_new && component.data.id)){
                    return false;
                }
                /*
                if(user.rol === 'ADMIN'){
                    return true;
                }
                 */
                if(this.isAdmin(user)){return true;}
                //console.log('canEditMedical', component.$auth.user(), component.data.can_edit_medical);
                return !!component.data.can_edit_medical;
            },
            canAskPatientSign(component) {
                const user = component.$auth.user();
                return !!user.branch_can_ask_patient_sign;
            },
            enableProtectionPatientsRepeat(component) {
                const user = component.$auth.user();
                return !!user.active_protection_patients_repeat;
            },
            canSendWhatsAppResults(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'enable.whats.results'
                })
            },
            userDevelopers(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                return ['CHRIGARC', 'LIDACA'].indexOf(user.key) > -1;
            },
            mobileAndTabletCheck() {
                const toMatch = [
                    /Android/i,
                    /webOS/i,
                    /iPhone/i,
                    /iPad/i,
                    /iPod/i,
                    /BlackBerry/i,
                    /Windows Phone/i
                ];

                return toMatch.some((toMatchItem) => {
                    return navigator.userAgent.match(toMatchItem);
                });
            },
            isAdmin(user){
                return user && (user.rol === 'ADMIN' || user.rol === 'ADMIMAX')
            },
            enablePaymentFormQuotation(component) {
                //ui.quotation.payment
                const $auth = component.$auth;
                const user = $auth.user();
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.quotation.payment'
                })
            },
            showTicketInput(component) {
                const $auth = component.$auth;
                const user = $auth.user();
                return  user && user.data_branch_office && !!user.data_branch_office.extra_settings.find(item => {
                    return item.type === 'ui.dispenser_ticket'
                })
            },
            enabledAlternativeNames(user) {
                if(this.isAdmin(user)){return true;}
                return user && user.enabled_alternative_names;
            },
            enabledDisableSamples(user) {
                if(this.isAdmin(user)){return true;}
                return user && user.enabled_disable_samples;
            },
            enabledUploadMaquila(user) {
                if(this.isAdmin(user)){return true;}
                return user && user.enabled_upload_maquila;
            },
            enablePhoneSell(user) {
                if(this.isAdmin(user)){return true;}
                return user && user.enabled_phone_sell_module;
            }
        }

        Vue.prototype.$lapi_validations = {
            passport(data) {
                //console.log(data)
                let result = {
                  message: '', status: true
                };
                if(!data.extra_1 || !data.extra_2){
                    result.status = false;
                    result.message = 'Se necesita capturar el pasaporte y la hora de la toma'
                }
                return result;
            },
            validateScotiabank(data){
                let result = {
                    message: '', status: true
                };

                if(!data.patient.employee){
                    result.status = false;
                    result.message += "Se necesita capturar el No. Empleado.\n"
                }

                if(!data.data.folio){
                    result.status = false;
                    result.message += "Se necesita capturar el folio.\n"
                }

                if(!data.medical.professional_licence){
                    result.status = false;
                    result.message += "Se necesita capturar la cedula del medico.\n"
                }

                let diagnostics = 0;

                if(data.diagnostics[0].code) diagnostics++;
                if(data.diagnostics[1].code) diagnostics++;

                if(diagnostics === 0){
                    result.status = false;
                    result.message += "Se necesita capturar un diagnostico.\n"
                }

                return result;
            },
            validateBAMEX(data){
                let result = {
                    message: '', status: true
                };

                if(!data.patient.employee){
                    result.status = false;
                    result.message += "Se necesita capturar el No. Empleado.\n"
                }

                if(!data.patient.beneficiary){
                    result.status = false;
                    result.message += "Se necesita capturar el valor de beneficiario.\n"
                }

                if(!data.data.folio){
                    result.status = false;
                    result.message += "Se necesita capturar el folio.\n"
                }

                if(!data.medical.speciality){
                    result.status = false;
                    result.message += "Se necesita capturar especialidad del médico.\n"
                }

                let diagnostics = 0;

                if(data.diagnostics[0].code) diagnostics++;
                if(data.diagnostics[1].code) diagnostics++;

                if(diagnostics === 0){
                    result.status = false;
                    result.message += "Se necesita capturar un diagnostico.\n"
                }

                if(!data.medical){
                    result.status = false;
                    result.message += "Se necesita seleccionar un médico\n"
                }else if(!data.medicalCode){
                    result.status = false;
                    result.message += "Se necesita la clave del médico con la empresa\n"
                }

                return result;
            }
        }

        Vue.prototype.$lapi_warnings = {
            passport(data) {
                let result = '';
                if(!data.extra_1 || !data.extra_2){
                    result += "No olvide capturar el pasaporte y la hora de la toma\n";
                }
                return result;
            },
        }

        Vue.prototype.$lapi_printer_managers = {
            jsprint: 'jsprint',
            lapiPrint: 'lapiPrint',
            VFP: 'VFP',
        }
    }
}
